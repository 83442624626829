const contact = {
    title: 'Get help',
    description: 'For help requests or product feedback, please contact us through the following methods.',
    discord: {
        title: 'Join Discord community',
        description: 'Connect with other developers to find solutions',
        button: 'Join',
    },
    github: {
        title: 'Communicate on GitHub',
        description: 'Create an issue and submit at GitHub',
        button: 'Open',
    },
    email: {
        title: 'Contact support via email',
        description: 'Send us an email for further information and help',
        button: 'Send',
    },
    reserve: {
        title: 'Reserve your time with Seitrace team',
        description: 'Quickly book a session for a live chat',
        button: 'Book',
    },
};
export default Object.freeze(contact);
